<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(clickAdd)">
        <div class="form-group">
          <label>
            <span class="mark">*</span>{{ i18n("Organization.DoubleAs") }}
          </label>
          <ValidationProvider
            tag="section"
            :name="i18n('Organization.DoubleAs')"
            rules="required"
            v-slot="{ errors }"
          >
            <treeselect
              :options="departmentOptions"
              :searchable="true"
              :show-count="true"
              :default-expand-level="1"
              placeholder="請選擇"
              value-format="object"
              v-model="department"
            />
            <span v-if="errors[0]" class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="form-group w-100">
          <label><span class="mark">*</span>{{ i18n("Basic.Supervisor") }}</label>
          <select class="form-control" v-model="IsSupervisor">
            <option :value="true">是</option>
            <option :value="false">否</option>
          </select>
        </div>

        <button
          type="button"
          class="btn btn-secondary btn-sm"
          style="margin-left: 6px; margin-right: 10px"
          @click="clickCancel"
        >
          {{ i18n("Buttons.Cancel") }}
        </button>
        <button type="submit" class="btn btn-success btn-sm">
          {{ i18n("Buttons.Save") }}
        </button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "AddDepartment",

  components: {
    Treeselect,
  },
  data() {
    return {
      // 部門List
      departmentOptions: [],
      // 選取的部門
      department: null,
      IsSupervisor: false,
    };
  },
  
  methods: {
    ///////////////////////////  多國語系  ///////////////////////////////////////////
    i18n (keyStr) {
      return this.$t(keyStr);
    },
    // 取得部門 API
    getDepartment() {
      const vm = this;
      const api = `${window.BaseUrl.api}/BasicInformation/department`;

      vm.$http
        .get(api)
        .then((response) => {
          if (response.status === 200) {
            vm.departmentOptions = [];
            vm.departmentOptions = response.data;
          }
        })
        .catch((error) => {
          vm.$bus.$emit("message: push", error.response.data, "danger");
        });
    },

    clickCancel() {
      this.$emit("addCancel");
    },
    clickAdd() {
      this.department.IsSupervisor = this.IsSupervisor;
      this.$emit("addDoubleAs", this.department);
    },
  },

  created() {
    this.getDepartment();
  },
};
</script>
