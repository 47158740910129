<template>
  <div
    id="messagModal"
    class="modal fade demand-message-modal"
    tabindex="-1"
    role="dialog"
    data-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div>
            <button
              type="button"
              class="close"
              style="margin-right: 3px"
              @click="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="result-list" style="margin-top: 16px">
            <div class="state-btn-wrap">
              <a
                class="success"
                href="javascript:void(0)"
                @click.prevent="messageState = 'successful'"
              >
                <eva-icon fill="#5380f7" name="checkmark-circle-2-outline" />
                成功 <span>{{ successfulList.length }}</span> 筆
              </a>
              <a
                class="failure"
                href="javascript:void(0)"
                @click.prevent="messageState = 'failed'"
              >
                <eva-icon fill="#df2e2e" name="close-circle-outline" />
                失敗 <span>{{ failedList.length }}</span> 筆
              </a>
            </div>
            <div
              class="result-person-list"
              :class="{ failed: messageState === 'failed' }"
            >
              <div v-if="messageState === 'successful'">
                <div
                  class="person"
                  v-for="(success, index) in successfulList"
                  :key="index"
                >
                  <img src="@/assets/img/head2.png" alt="" />
                  {{ success }}
                </div>
              </div>
              <div v-else>
                <div
                  class="person"
                  v-for="(fail, index) in failedList"
                  :key="index"
                >
                  <img src="@/assets/img/head2.png" alt="" />
                  {{ fail }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global $ */
import { mapGetters } from "vuex";

export default {
  props: ["successfulList", "failedList"],

  name: "ResponseDataModal",
  data() {
    return {
      messageState: "successful", // 狀態(預設顯示成功)
    };
  },

  computed: {
    ...mapGetters(["showResponseData"]),
  },

  watch: {
    showResponseData(e) {
      e ? $("#messagModal").modal("show") : $("#messagModal").modal("hide");
    },
  },

  methods: {
    closeModal() {
      this.$store.commit("OpenResponseDataModal", false);
    },
  },

  destroyed() {
    this.$store.commit("OpenResponseDataModal", false);
  },
};
</script>

<style lang="scss" scoped>
#messagModal {
  padding: 0;
  .modal-content {
    padding: 0;
  }

  .modal-header {
    position: relative;
    display: none;
  }
  .modal-body {
    padding: 0;
    .result-info {
      flex: 1;
      min-width: 1px;
      padding: 10px;

      img {
        width: 100px;
        vertical-align: middle;
      }
      .title {
        display: inline-block;
        vertical-align: middle;

        span {
          display: block;
          font-size: 1.3em;
        }
      }
    }

    .result-list {
      padding: 10px;

      .state-btn-wrap {
        display: flex;

        a {
          flex: 1;
          display: inline-block;
          color: #000;
          padding: 10px;
          border-radius: 5px 5px 0 0;
          i {
            margin-right: 10px;
          }
          span {
            font-size: 1.5em;
            font-weight: bold;
            margin: 0 10px;
          }

          &.success {
            background-color: #e4eafa;
          }
          &.failure {
            background-color: #fdd1d1;
          }
        }
      }

      & > img {
        width: 200px;
        display: none;
      }

      .result-person-list {
        background-color: #e4eafa;
        padding: 20px;

        &.failed {
          background-color: #fdd1d1;
        }
        .person {
          display: flex;
          color: #000;
          align-items: center;
          background: #fff;
          margin-bottom: 5px;
          padding: 5px;
          border-radius: 25px;
          &:last-child {
            margin-bottom: 0;
          }

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 2px solid #000;
            margin-right: 10px;
            background-color: #fff;
          }
        }
      }
    }
  }
}
</style>
